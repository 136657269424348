var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-card",
    { staticClass: "p-2 BaseQuestionManage" },
    [
      _c("base-question-modal", {
        ref: "modal",
        attrs: { mode: "manage" },
        on: {
          action: function ($event) {
            return _vm.questionAction($event)
          },
          "new-question-pressed": _vm.newQuestion,
        },
      }),
      _c(
        "vs-popup",
        {
          attrs: {
            active: _vm.showForm,
            title: _vm.popupTitle,
            fullscreen: "true",
          },
          on: {
            "update:active": function ($event) {
              _vm.showForm = $event
            },
          },
        },
        [
          _c("base-question-form", {
            ref: "form",
            attrs: { preserveModel: true, service: _vm.service },
            on: {
              "confirm-alteration": _vm.storeQuestion,
              "cancel-alteration": function ($event) {
                _vm.showForm = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }