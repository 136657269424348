var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "panel-margin relative pb-2 _question-panel" },
    [
      _c(
        "div",
        { staticClass: "__difficulty-text" },
        [
          _c("difficulty-indicator", {
            attrs: { difficulty: _vm._.get(_vm.item, "data.difficulty", "") },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "flex mb-4" }, [
        _c(
          "div",
          {
            staticClass: "bg-grid-color-secondary mr-2",
            staticStyle: { width: "100%" },
          },
          [
            _c("ComplexEditor", {
              attrs: {
                questionnaire_id: _vm.parent_id,
                editor_data: _vm.editorData,
                placeholder: `${_vm.$t("digite-a-questao-aqui")}...`,
                autoOpen: _vm.autoOpenEditor,
                dontClose: _vm.dontCloseEditor,
              },
              on: {
                "update:editor_data": function ($event) {
                  _vm.editorData = $event
                },
                blur: _vm.onBlur,
                destroy: _vm.onDestroy,
                "data-changed": _vm.onDataChange,
              },
            }),
          ],
          1
        ),
      ]),
      _vm.questionnaireMode
        ? _c("Footer", {
            attrs: {
              showInvalidateButton: _vm.showInvalidateButton,
              item: _vm.item,
              parent_id: _vm.parent_id,
              cheetEnabled: _vm.cheetEnabled,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }